import { Title } from '@solidjs/meta';
import { DialogContent, DialogAction, DialogActions, DialogTrigger, Dialog } from '@troon/ui';
import { StyleCard } from '../_style-card';

export default function DialogPage() {
	return (
		<>
			<Title>Dialog | Components | Style guide | Troon</Title>
			<h1 class="text-3xl font-semibold">Dialog</h1>
			<div class="mb-8 grid grid-cols-12 gap-4">
				<StyleCard title="Dialog">
					<div class="self-center">
						<Dialog key="styleguide">
							<DialogTrigger>Show dialog</DialogTrigger>
							<DialogContent header="This is a dialog" headerLevel="h2" autoWidth>
								<>
									<p>
										This is content inside of a dialog asdfkluhasdf;jkl asdlkjhasdfkl jasdfljmhasdfl kjbasdflkhj
										asdflmhjhb asdflkjbasdfl kjhasdflkj hasdflkjhasdfkl jhasdflkj hasdfljk
									</p>
									<DialogActions>
										<DialogAction onClick={close}>close</DialogAction>
									</DialogActions>
								</>
							</DialogContent>
						</Dialog>
					</div>
				</StyleCard>
			</div>
		</>
	);
}
